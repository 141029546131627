/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, createContext, useState} from 'react';
import {WithChildren} from '../../../../../../_metronic/helpers';

interface cardsSelectionObjectType {
  selectedCards: string[];
  select: (id: string) => void;
  selectRange: (ids: string[]) => void;
  unSelect: (id: string) => void;
  checkSelected: (id: string) => boolean;
  clearAll: () => void;
  count: number;
}
const initialValues: cardsSelectionObjectType = {
  selectedCards: [],
  select: () => {},
  selectRange: () => {},
  unSelect: () => {},
  checkSelected: () => false,
  clearAll: () => {},
  count: 0,
};

const CardsSelectionContext = createContext(initialValues);

const CardsSelectionProvider: FC<WithChildren> = ({children}) => {
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const select = (id: string) => setSelectedCards([...selectedCards, id]);
  const selectRange = (ids: string[]) =>
    setSelectedCards([...selectedCards, ...ids.filter((d) => !selectedCards.includes(d))]);

  const unSelect = (id: string) => setSelectedCards([...selectedCards.filter((d) => d !== id)]);
  const checkSelected = (id: string) => !!selectedCards.find((d) => d === id);
  const clearAll = () => setSelectedCards([]);

  return (
    <CardsSelectionContext.Provider
      value={{
        selectedCards,
        select,
        selectRange,
        unSelect,
        checkSelected,
        clearAll,
        count: selectedCards.length,
      }}
    >
      {children}
    </CardsSelectionContext.Provider>
  );
};

const useCardsSelection = () => useContext(CardsSelectionContext);

export {CardsSelectionProvider, useCardsSelection};
