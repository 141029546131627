import {useEffect, useState} from 'react';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {compareArrays, parseError} from '../../../../../shared/utils';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {SampleVideos} from './videos.data';
import VideoCard from './components/VideoCard';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import useVideosPageStore from './videos-page.store';
import {VideosSearch} from './components/VideosSearch';
import {ModelsEmptyPlaceholder} from '../../../models-management/pages/models-list/components/ModelsEmptyPlaceholder';
import {getAllVideos} from '../../vidoes.api';
import {EmptyPlaceholder} from '../../../../../shared/components/EmptyPlaceholder';
import {Video, VideoStatusEnum} from '../../vidoes.types';
import {KTSVG} from '../../../../../_metronic/helpers';
import BlockUi from 'react-block-ui';
import {ModelStatusesEnum} from '../../../models-management/types';
import {useCardsSelection} from '../../../models-management/pages/models-list/core/CardsSelectionProvider';
import {VideosSelectionOptions} from './components/VideosSelectionOptions';
import {VideosQueryProvider, useQueryResponseData, useVideosQuery} from './VideosQueryProvider';
import {
  PaginatorCurrentPageReportOptions,
  PaginatorRowsPerPageDropdownOptions,
} from 'primereact/paginator';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'react-bootstrap';
import {VideoSorting} from './components/VideoSorting';
import {VideosPagination} from './components/VideosPagination';
import {VideosFilter} from './components/VideosFilters';

const REFRESH_TIME = 15 * 1000;

const VideosPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {filteredVideos, setVideos, videos, reset, filters, updateFilters} = useVideosPageStore();
  const {clearAll, count} = useCardsSelection();
  const {isLoading, refetch, response, query} = useVideosQuery();
  const res = useQueryResponseData();

  useEffect(() => {
    return () => {
      reset();
      clearAll();
    };
  }, []);

  useEffect(() => {
    // Auto refresh on pending models
    let interval: NodeJS.Timer;

    const hasPending = videos?.find(
      (m) =>
        m.status &&
        [VideoStatusEnum.PROCESSING.toString(), VideoStatusEnum.PENDING.toString()].includes(
          m.status.toString()
        )
    );
    if (hasPending) interval = setInterval(() => refetch(), REFRESH_TIME);

    return () => interval && clearInterval(interval);
  }, [videos]);

  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: '3D Experiences', path: 'videos', isActive: false}]}>
        My Experiences
      </PageTitle>

      <div className='app-container '>
        <header className='d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex align-items-center'>
            <h2>My Experiences</h2>
            <div className='ms-3 d-flex align-items-center'>
              <VideosSearch />

              <button
                className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
                onClick={() => {
                  refetch();
                }}
                title='Refresh models and reset cache'
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className='svg-icon-muted svg-icon-4'
                  />
                </span>
              </button>

              {count > 0 && (
                <VideosSelectionOptions videos={response?.data} onReload={() => refetch()} />
              )}
            </div>
          </div>
          <div className='tools d-flex'>
            <ul className='nav nav-pills me-6 mb-2 mb-sm-0'>
              <li className='nav-item m-0'>
                <VideoSorting />
              </li>
              <li className='nav-item m-0'>
                <VideosFilter />
              </li>
            </ul>

            <Link
              to={`/dashboard/models?from3DExp=1&status=${ModelStatusesEnum.COMPLETED}`}
              className={clsx('btn btn-info btn-sm fw-bold ms-2')}
            >
              Generate 3D Experience
            </Link>
          </div>
        </header>

        <BlockUi tag='div' blocking={isLoading} message='Loading 3D Experiences ...' keepInView>
          <div className='min-h-200px'>
            {response?.data?.length === 0 && (
              <EmptyPlaceholder
                label='No experiences found!'
                description='Try generating a new 3D Experience or search with different keywords'
              />
            )}
            <div className='row g-10'>
              {response?.data?.map((video) => (
                <div className='col-sm-4 col-lg-3'>
                  <VideoCard key={video.id} video={video} onUpdate={() => refetch()} />
                </div>
              ))}
            </div>
          </div>
        </BlockUi>

        <VideosPagination />
      </div>
    </div>
  );
};

export default VideosPage;
