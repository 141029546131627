import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import VideosPage from './pages/VideosPage/VideosPage';
import Templates from './pages/Templates/Templates';
import ExperienceWizard from './pages/ExperienceWizard/ExperienceWizard';
import {CardsSelectionProvider} from '../models-management/pages/models-list/core/CardsSelectionProvider';
import {VideosQueryProvider} from './pages/VideosPage/VideosQueryProvider';

const Videos = () => {
  return (
    <VideosQueryProvider>
      <CardsSelectionProvider>
        <Routes>
          <Route path='my-videos' element={<VideosPage />} />
          <Route path='templates' element={<Templates />} />
          {/* <Route
        path='generate'
        element={<ExperienceWizard modelsIds={[]} submitComplete={() => {}} />}
      /> */}

          <Route index element={<Navigate to='my-videos' replace />} />
        </Routes>
      </CardsSelectionProvider>
    </VideosQueryProvider>
  );
};

export default Videos;
