import {create} from 'zustand';
import {Video} from '../../vidoes.types';
import {Model} from '../../../models-management/types';
import {QueryState} from '../../../../../_metronic/helpers';

interface IStore {
  reset: () => void;

  videos?: Video[];
  filteredVideos?: Video[];
  setVideos: (videos: Video[]) => void;

  searchKey: string;
  setSearchKey: (key: string) => void;

  filters: QueryState;
  updateFilters: (updates: Partial<QueryState>) => void;
}

const useVideosPageStore = create<IStore>((set, get) => ({
  videos: [],
  setVideos: (videos) => set({videos: videos, filteredVideos: videos}),

  searchKey: '',
  setSearchKey: (key) => {
    set({
      searchKey: key,
      filteredVideos: get().videos?.filter((v) =>
        v.name.toLocaleLowerCase().includes((key || '').trim().toLocaleLowerCase())
      ),
    });
  },

  filters: {
    items_per_page: 8,
    page: 1,
  },

  updateFilters: (updates: Partial<QueryState>) => {
    const filters = get().filters;
    const updatedState = {...filters, ...updates} as QueryState;
    set({filters: updatedState});
  },

  reset: () => {
    set({
      videos: [],
      filteredVideos: [],
      searchKey: '',
      filters: {
        items_per_page: 8,
        page: 0,
      },
    });
  },
}));

export default useVideosPageStore;
