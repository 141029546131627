import React, {useEffect, useState} from 'react';
import useCheckoutStore from '../checkout.store';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import {createUserByAdmin, createResellerCustomer} from '../../../admin/api/users-api';
import {ApiError} from '../../../auth/core/apiModels';
import clsx from 'clsx';
import {FormFieldErrorMessage} from '../../../../../shared/components/FormFieldErrorMessage';
import {ModelHeaderLayout} from '../../../shared/components/ModalLayout';
import {PaymentMethod} from '../../types';
import {InputMask} from 'primereact/inputmask';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {InputNumber} from 'primereact/inputnumber';
import {InputSwitch} from 'primereact/inputswitch';
import {createHostedFieldsToken} from '../../bluesnap-api';

const initialValues: PaymentMethod = {
  cardHolder: '',
  cardNumber: 0,
  last4numbers: '',
  cardType: '',
  cvv: undefined,
  expireMonth: 0,
  expireYear: 0,
  saveCard: false,
};

const validationSchema = Yup.object().shape({
  cardHolder: Yup.string().min(3, 'Minimum 3 symbols').required('Name on card is required'),
  cardNumber: Yup.number().required('Card number is required'),
  cvv: Yup.number().required('CVV is required'),

  expireMonth: Yup.number().min(1).max(12).required('Expire Month is required'),
  expireYear: Yup.number().required('Expire Year is required'),
});

interface Props {
  submitComplete: (method: PaymentMethod | undefined) => void;
}

const AddPaymentMethod = ({submitComplete}: Props) => {
  const {newPaymentMethod, setNewPaymentMethod} = useCheckoutStore();
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik<PaymentMethod>({
    initialValues: {...initialValues},
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const pftoken = await createHostedFieldsToken();

      // 1-  we need a new api, createHostedFieldsToken, params: credit card, returns: pftoken

      //   Validate
      // if (
      //   !formik.values.is_org_admin &&
      //   !formik.values.is_super_admin &&
      //   formik.values.role === undefined
      // ) {
      //   setStatus('Please select the Role');
      //   formik.setFieldError('role', 'Required!');
      //   return;
      // }
      if (!formik.values.cardNumber) return;

      //   setNewPaymentMethod({
      //     ...formik.values,
      //     last4numbers: +formik.values.cardNumber.toString().substring(12),
      //   });
      //   submitComplete(formik.values);

      // try {
      //   if (currentUser?.is_super_admin) await createUserByAdmin({...values});
      //   else await createResellerCustomer(values);
      //   toast.success('User saved successfully');
      //   setSubmitted(true);
      //   submitComplete();
      // } catch (error: any) {
      //   console.error(error);
      //   setSubmitting(false);
      //   setLoading(false);
      //   const responseData: ApiError | undefined = error?.response?.data;
      //   if (responseData?.msg) setStatus(responseData?.msg);
      //   else setStatus('Failed to submit new user');
      // }
    },
  });

  useEffect(() => {
    const number = formik.values.cardNumber?.toLocaleString();

    if (!number) {
      formik.setFieldValue('cardType', '');
      return;
    }

    if (number.startsWith('4')) formik.setFieldValue('cardType', 'visa');
    if (number.startsWith('2')) formik.setFieldValue('cardType', 'mastercard');
    if (number.startsWith('5')) formik.setFieldValue('cardType', 'mastercard');
  }, [formik.values.cardNumber]);

  return (
    <div>
      <ModelHeaderLayout
        title='Add payment method method'
        showCloseButton
        onClose={submitComplete}
      ></ModelHeaderLayout>

      <main className={clsx('modal-body p-5 px-6 scroll')}>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='user-form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          <form
            id='kt_modal_new_card_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            action='#'
          >
            <fieldset className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Name On Card</span>
                <span
                  className='ms-1'
                  data-bs-toggle='tooltip'
                  aria-label="Specify a card holder's name"
                  data-bs-original-title="Specify a card holder's name"
                  data-kt-initialized={1}
                >
                  <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                    <span className='path1' />
                    <span className='path2' />
                    <span className='path3' />
                  </i>
                </span>{' '}
              </label>
              <input
                type='text'
                className='form-control form-control-solid text-uppercase '
                placeholder=''
                {...formik.getFieldProps('cardHolder')}
              />

              {formik.touched.cardHolder && formik.errors.cardHolder && (
                <FormFieldErrorMessage>{formik.errors.cardHolder}</FormFieldErrorMessage>
              )}
            </fieldset>
            <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
              <label className='required fs-6 fw-semibold form-label mb-2'>Card Number</label>
              <div className='position-relative'>
                <div className='p-inputgroup flex-1'>
                  <InputMask
                    unmask
                    className='w-100 bg-light border-0'
                    id='card-number'
                    mask='9999-9999-9999-9999'
                    placeholder='XXXX-XXXX-XXXX-XXXX'
                    data-bluesnap='ccn'
                    onChange={(e: any) => formik.setFieldValue('cardNumber', e.value)}
                    autoComplete='cc-number'
                  ></InputMask>
                  <span className='p-inputgroup-addon border-0 bg-light'>
                    {formik.values.cardType === 'visa' && (
                      <img
                        src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                        alt=''
                        className='h-25px  '
                      />
                    )}

                    {formik.values.cardType === 'mastercard' && (
                      <img
                        src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                        alt=''
                        className='h-25px'
                      />
                    )}
                  </span>
                </div>
              </div>
              {formik.touched.cardNumber && formik.errors.cardNumber && (
                <FormFieldErrorMessage>{formik.errors.cardNumber}</FormFieldErrorMessage>
              )}
            </div>
            <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-semibold form-label mb-2'>Expiration Date</label>
                <div className='row fv-row fv-plugins-icon-container'>
                  <div className='col-6'>
                    <Dropdown
                      className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                      value={formik.values.expireMonth}
                      onChange={(e: any) => formik.setFieldValue('expireMonth', e.value)}
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                      placeholder='Month'
                    />
                  </div>
                  <div className='col-6'>
                    <Dropdown
                      className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                      value={formik.values.expireYear}
                      onChange={(e: any) => formik.setFieldValue('expireYear', e.value)}
                      options={[2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034]}
                      placeholder='Year'
                    />
                  </div>
                </div>
                {formik.touched.expireYear && formik.errors.expireYear && (
                  <FormFieldErrorMessage>Expiration date is required</FormFieldErrorMessage>
                )}
                {formik.touched.expireMonth && formik.errors.expireMonth && (
                  <FormFieldErrorMessage>Expiration date is required</FormFieldErrorMessage>
                )}
              </div>
              <div className='col-md-4 fv-row fv-plugins-icon-container'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>CVV</span>
                  <span
                    className='ms-1'
                    data-bs-toggle='tooltip'
                    aria-label='Enter a card CVV code'
                    data-bs-original-title='Enter a card CVV code'
                    data-kt-initialized={1}
                  >
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                    </i>
                  </span>
                </label>
                <div className='position-relative'>
                  <div className='p-inputgroup flex-1 pt-2'>
                    <InputMask
                      mask='999?9'
                      slotChar=' '
                      unmask
                      className='border-0 bg-light '
                      value={formik.values.cvv + ''}
                      data-bluesnap='cvv'
                      onChange={(e: any) => formik.setFieldValue('cvv', +e.value)}
                    />
                    <div className='p-inputgroup-addon border-0 bg-light pt-2'>
                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <i className='ki-duotone ki-credit-cart fs-2hx'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                {formik.touched.cvv && formik.errors.cvv && (
                  <FormFieldErrorMessage>{formik.errors.cvv}</FormFieldErrorMessage>
                )}
              </div>
            </div>
            <div className='d-flex flex-stack'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold form-label'>
                  Save Card for further billing?
                </label>
                <div className='fs-7 fw-semibold text-muted'>
                  If you need more info, please check budget planning
                </div>
              </div>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <InputSwitch
                  checked={formik.values.saveCard}
                  onChange={(e) => formik.setFieldValue('saveCard', e.checked)}
                />

                <span className='form-check-label fw-semibold text-muted'>Save Card</span>
              </label>
            </div>
            <div className='text-center pt-15'>
              <button
                type='button'
                onClick={() => submitComplete(undefined)}
                className='btn btn-light me-2'
              >
                Discard
              </button>
              <button
                type='button'
                onClick={() => formik.submitForm()}
                className='btn btn-primary'
                disabled={!formik.isValid}
              >
                Submit
              </button>
            </div>
          </form>
        </form>
      </main>
    </div>
  );
};

export default AddPaymentMethod;
