import React, {useState} from 'react';
import {useCardsSelection} from '../../../../models-management/pages/models-list/core/CardsSelectionProvider';
import clsx from 'clsx';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';
import {usePermissionChecker} from '../../../../admin/pages/roles/hooks/permission-checker';
import {deleteBulkModels} from '../../../../models-management/api/models-api';
import {deleteDialogProps, warningDialogProps} from '../../../../shared/types';
import {
  deleteBulkTemplates,
  publishBulkTemplates,
  unpublishBulkTemplates,
} from '../../../vidoes.api';
const DELETE_MESSAGE = `Are you sure you want to delete the selected templates?`;

export function TemplatesSelectionOptions({templates, onReload}: any) {
  const {count, selectedCards, selectRange, clearAll} = useCardsSelection();
  const {hasPermission, hasNoPermission, hasAnyPermission} = usePermissionChecker();
  const [deleting, setDeleting] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [unpublishing, setUnpublishing] = useState(false);

  const deleteItems = async () => {
    confirmDialog({
      ...deleteDialogProps,
      message: DELETE_MESSAGE,
      accept: async () => {
        try {
          setDeleting(true);

          await deleteBulkTemplates(selectedCards);

          clearAll();

          onReload();
          toast.success('Templates deleted successfully');
        } catch (error) {
          toast.error('Failed to delete selected Templates, please contact support!');
          console.error(error);
        }
        setDeleting(false);
      },
    });
  };

  const publishItems = async () => {
    try {
      setPublishing(true);

      await publishBulkTemplates(selectedCards);

      clearAll();

      onReload();
      toast.success('Templates published successfully');
    } catch (error) {
      toast.error('Failed to publish selected Templates, please contact support!');
      console.error(error);
    }
    setPublishing(false);
  };

  const unpublishItems = async () => {
    try {
      setUnpublishing(true);

      await unpublishBulkTemplates(selectedCards);

      clearAll();

      onReload();
      toast.success('Templates unpublished successfully');
    } catch (error) {
      toast.error('Failed to unpublish selected Templates, please contact support!');
      console.error(error);
    }
    setUnpublishing(false);
  };

  const selectAll = () => {
    selectRange(templates.map((d: any) => d.pub_id + ''));
  };

  return (
    <div className='d-flex align-items-center ms-8  fw-bold'>
      <div className='me-2'>Selected: {count}</div>

      <button
        className='btn btn-sm  btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
        onClick={publishItems}
        title='Publish selected draft templates'
      >
        {!publishing && (
          <span>
            <i className='ki-solid ki-verify fs-4 me-1'></i>
            Publish
          </span>
        )}

        {publishing && (
          <span className='indicator-progress d-block z-1 text-info'>
            Publishing...
            <span className='spinner-border spinner-border-sm align-middle '></span>
          </span>
        )}
      </button>

      <button
        className='btn btn-sm  btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
        onClick={unpublishItems}
        title='Set published templates as draft'
      >
        {!unpublishing && (
          <span>
            <i className='ki-outline ki-verify fs-4 me-1'></i>
            Unpublish
          </span>
        )}

        {unpublishing && (
          <span className='indicator-progress d-block z-1 text-info'>
            Unpublishing...
            <span className='spinner-border spinner-border-sm align-middle '></span>
          </span>
        )}
      </button>

      <button
        title='Delete'
        className={clsx(
          'btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-danger fw-bold ms-2  position-relative ',
          {'pe-none text-danger border-danger border': deleting}
        )}
        onClick={deleteItems}
      >
        {!deleting && <i className='fas fa-trash  '></i>}

        {deleting && (
          <span className='indicator-progress d-block z-1 text-info'>
            <span className='spinner-border spinner-border-sm align-middle '></span>
          </span>
        )}
      </button>

      <div className='border-gray-300 border-right-1 h-25px w-10px'></div>

      {/* Select All Button */}
      <button
        className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
        onClick={selectAll}
        title='Select all models in this page'
      >
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16px' height='16px'>
          <path
            fill='currentColor'
            d='M 4 2 C 2.895 2 2 2.895 2 4 L 2 16 C 2 17.105 2.895 18 4 18 L 16 18 C 17.105 18 18 17.105 18 16 L 18 4 C 18 2.895 17.105 2 16 2 L 4 2 z M 4 4 L 16 4 L 16 16 L 4 16 L 4 4 z M 20 6 L 20 20 L 6 20 L 6 22 L 20 22 C 21.105 22 22 21.105 22 20 L 22 6 L 20 6 z M 13.292969 6.2929688 L 9 10.585938 L 6.7070312 8.2929688 L 5.2929688 9.7070312 L 9 13.414062 L 14.707031 7.7070312 L 13.292969 6.2929688 z'
          />
        </svg>
      </button>

      {/* Clear Button */}
      <button
        className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
        onClick={clearAll}
        title='Clear Selection'
      >
        <i className='fas fa-backspace '></i>

        {/* Clear Selection */}
      </button>
    </div>
  );
}
