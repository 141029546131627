import React from 'react';
import useViewerStore from '../viewer-store';
import clsx from 'clsx';

export function ViewerToolbar(props: any) {
  const {config, setConfig, showOccluder, setShowOccluder, reset} = useViewerStore();

  return (
    <div className='position-absolute right-0 z-3 me-10'>
      {props.category_id === 2 && (
        <div
          className='btn btn-icon btn-sm btn-active-light-primary ms-2 '
          title='Toggle occluder'
          onClick={() => {
            setShowOccluder(!showOccluder);
          }}
          style={{}}
        >
          <i
            className={clsx(
              'fs-2 ki-duotone ',
              {
                'ki-toggle-on-circle text-primary': showOccluder,
              },
              {
                'ki-toggle-off-circle text-gray-600': !showOccluder,
              }
            )}
          >
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </div>
      )}
    </div>
  );
}
