import clsx from 'clsx';
import {ReactNode, useState} from 'react';
import {useThemeMode} from '../../../../_metronic/partials';
import {Dialog} from 'primereact/dialog';
import ReportIssue from '../../app/ReportIssue';

interface Props {
  title?: string;
  okButtonText?: string;
  closeButtonText?: string;
  onClose: () => void;
  onOk?: () => void;
  showFooter?: boolean;
  showHeader?: boolean;
  showOkButton?: boolean;
  showCloseButton?: boolean;
  loading?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
}

export function ModelHeaderLayout(props: any) {
  return (
    <header className='modal-header'>
      <h5
        className='modal-title capitalized d-flex align-items-center flex-row'
        title={props.title}
      >
        <span className='text-truncate mw-250px d-inline-block'>{props.title}</span>

        <span className='ms-3'>{props.children}</span>
      </h5>

      <div>
        {props.showCloseButton && (
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.onClose}
          >
            <span className='svg-icon svg-icon svg-icon-2x'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='mh-50px'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                ></rect>
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                ></rect>
              </svg>
            </span>
          </div>
        )}
      </div>
    </header>
  );
}

export const ModalLayout = ({
  title,
  showCloseButton,
  showFooter,
  showOkButton,
  onClose,
  onOk,
  children,
  loading,
  okButtonText,
  large,
  fullWidth,
}: Props) => {
  const {mode} = useThemeMode();

  return (
    <>
      <ModelHeaderLayout
        title={title}
        showCloseButton={showCloseButton}
        onClose={onClose}
      ></ModelHeaderLayout>

      <main
        className={clsx(
          'modal-body p-5 px-6 scroll d-flex flex-column',
          {'modal-lg': large},
          {'full-width': fullWidth},
          {'overlay overlay-block': loading}
        )}
      >
        {children}
      </main>

      {showFooter && (
        <footer className='modal-footer p-5 border-top'>
          {showCloseButton && (
            <button type='button' onClick={onClose} className='btn btn-light me-2'>
              Close
            </button>
          )}
          {showOkButton && (
            <button type='button' onClick={onOk} className='btn btn-primary'>
              {!loading && <span>{okButtonText || 'Save changes'}</span>}
              {loading && (
                <span>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </footer>
      )}
    </>
  );
};
