export type Video = {
  id: number;
  pub_id?: string;
  name: string;
  message?: string;
  create_date: Date;
  location?: string;
  number_of_models?: number;
  thumbnail: string;
  video?: string;
  is_deleted?: boolean;
  is_downloaded?: boolean;
  is_shared?: boolean;
  published?: boolean;
  deleted?: boolean;
  downloaded_time?: Date;
  reason?: string;
  views_count?: number;
  experience_name?: string;
  created_by?: {id: number; name: string};
  overlay?: string;

  modelId: number; // pubid
  modelName?: string;
  length: number; // in seconds
  status: VideoStatusEnum;
};

export enum VideoStatusEnum {
  FAILED = -1,
  PENDING,
  PROCESSING,
  COMPLETED,
}

export type TemplatesFilter = {
  search?: string;
  filter_pub_template_id?: string;
  filter_number_of_models?: number;
};

export type VideosFilter = {
  search?: string;
  filter_user_id?: string;
  filter_experience_pub_id?: number;
};

export const videosStatuses = [
  {id: '-1', label: 'Error'},
  {id: '0', label: 'Pending'},
  {id: '1', label: 'Processing'},
  {id: '2', label: 'Done'},
];
