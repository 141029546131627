import useVideosPageStore from '../videos-page.store';

export function VideosSearch() {
  const {searchKey, setSearchKey, filters, updateFilters} = useVideosPageStore();

  return (
    <form
      // onSubmit={onSubmit}
      className='d-flex align-items-center align-items-center ms-4'
    >
      <div className='d-flex align-items-center position-relative my-1'>
        {/* begin::Svg Icon | path: icons/duotune/general/gen021.svg */}
        <span className='svg-icon svg-icon-3 position-absolute ms-3'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              opacity='0.5'
              x='17.0365'
              y='15.1223'
              width='8.15546'
              height='2'
              rx='1'
              transform='rotate(45 17.0365 15.1223)'
              fill='currentColor'
            ></rect>
            <path
              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
              fill='currentColor'
            ></path>
          </svg>
        </span>
        {/* end::Svg Icon */}
        <input
          type='text'
          className='form-control form-control-sm border-body bg-body w-200px px-10'
          placeholder='Search by name'
          value={filters.search}
          onChange={(e) => updateFilters({search: e.target.value})}
        />
        {searchKey && (
          <span
            className='clear-btn svg-icon svg-icon-3 position-absolute cursor-pointer bg-white'
            style={{
              right: '0.75rem',
            }}
            title='Clear search'
            onClick={() => updateFilters({search: ''})}
          >
            <i className='las la-times fs-4 text-black'></i>
          </span>
        )}
      </div>
    </form>
  );
}
