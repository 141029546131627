import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import {ErrorPlaceholder} from '../../../../../../shared/components/ErrorPlaceholder';
import {parseError} from '../../../../../../shared/utils';
import {toAbsoluteUrl, KTSVG} from '../../../../../../_metronic/helpers';
import {ModelsCounter, ModelStatusesEnum, UserRolesEnum} from '../../../../models-management/types';
import {getAllModelsCount} from '../../../api/users-api';
import useRealUserRole from '../../../../app/core/useCheckRole';
import {useAuth} from '../../../../auth';

const ModelsCountWidget = () => {
  const [error, setError] = useState('');
  const [modelsCount, setModelsCount] = useState<ModelsCounter>();
  const role = useRealUserRole();
  const {currentUser} = useAuth();

  useEffect(() => {
    const get = async () => {
      try {
        setModelsCount(await getAllModelsCount());
      } catch {
        setError(parseError(error));
      }
    };
    get();
  }, []);

  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div className='card card-flush h-xl-100'>
      <div
        className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/top-green.png')})`}}
        data-bs-theme='light'
      >
        <h3 className='card-title align-items-start flex-column text-white pt-15'>
          <span className='fw-bold fs-2x mb-3'>Models Stats</span>
          <div className='fs-4 text-white'>
            <span className='opacity-75'>
              The following statics shows count of all models according to its status
            </span>
          </div>
        </h3>

        <div className='card-toolbar pt-5'></div>
      </div>

      <div className='card-body mt-n20'>
        <div className='mt-n20 position-relative'>
          <div className='row g-3 g-lg-6'>
            <div className='col-6'>
              <Link to={`/dashboard/models`}>
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs027.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.total || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Total</span>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-6'>
              <Link to={`/dashboard/models?status=${ModelStatusesEnum.COMPLETED}`}>
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen037.svg'
                          className='svg-icon-success svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-success fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.done || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Done</span>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-6'>
              <Link to={`/dashboard/models?status=${ModelStatusesEnum.PROCESSING}`}>
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs019.svg'
                          className='svg-icon-primary svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-primary fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.processing || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Processing</span>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-6'>
              <Link to={`/dashboard/models?status=${ModelStatusesEnum.FAILED}`}>
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen034.svg'
                          className='svg-icon-danger svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-danger fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.error || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Error</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-6'>
              <Link to={`/dashboard/models?rate=${5}`}>
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen037.svg'
                          className='svg-icon-success svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-success fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.approved || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Approved</span>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-6'>
              {currentUser?.is_super_admin && (
                <Link to={`/dashboard/models?rate=${0}`}>
                  <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <span className='symbol-label'>
                        <span className='svg-icon svg-icon-1 svg-icon-primary'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen034.svg'
                            className='svg-icon-danger svg-icon-2hx'
                          />
                        </span>
                      </span>
                    </div>

                    <div className='m-0'>
                      <span className='text-danger fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        <CountUp end={modelsCount?.rejected || 0} duration={1} />
                      </span>

                      <span className='text-gray-500 fw-semibold fs-6'>Rejected</span>
                    </div>
                  </div>
                </Link>
              )}

              {!currentUser?.is_super_admin && (
                <div className='bg-light bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen034.svg'
                          className='svg-icon-danger svg-icon-2hx'
                        />
                      </span>
                    </span>
                  </div>

                  <div className='m-0'>
                    <span className='text-danger fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={modelsCount?.rejected || 0} duration={1} />
                    </span>

                    <span className='text-gray-500 fw-semibold fs-6'>Rejected</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelsCountWidget;
