import React, {useState} from 'react';
import useCheckoutStore from '../checkout.store';
import {CardSubmitResponse} from '../../bluesnap-api';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
declare var bluesnap: any;

export function BluesnapHostedFields() {
  const {setNewPaymentMethod, newPaymentMethod, cardError, cardType, pfErrors, setPFErrors} =
    useCheckoutStore();
  const [error, setError] = useState<string>();
  const [cardHolder, setCardHolder] = useState('');

  const submitCard = () => {
    if (!cardHolder) return setError('Please enter card holder name');

    setError(cardError);

    bluesnap.hostedPaymentFieldsSubmitData((response: CardSubmitResponse) => {
      if (null != response.cardData) {
        var fraudSessionId = response.transactionFraudInfo.fraudSessionId;

        setNewPaymentMethod({
          saveCard: false,
          response: response,
          last4numbers: response.cardData.last4Digits,
          cardType: response.cardData.ccType.toLocaleLowerCase(),
          cardHolder,
        });

        console.log(response);

        console.log(
          'card type: ' +
            response.cardData.ccType +
            ', last 4 digits: ' +
            response.cardData.last4Digits +
            ', exp: ' +
            response.cardData.exp +
            ', issuing Country: ' +
            response.cardData.issuingCountry +
            ', isRegulatedCard: ' +
            response.cardData.isRegulatedCard +
            ', cardSubType: ' +
            response.cardData.cardSubType +
            ', binCategory: ' +
            response.cardData.binCategory +
            ', cardCategory: ' +
            response.cardData.cardCategory +
            ', ccBin: ' +
            response.cardData.ccBin +
            ', issuing bank: ' +
            response.cardData.ccIssuingBank +
            ' and fraudSessionId: ' +
            fraudSessionId +
            ', after that I can call final submit'
        );
        // submit the form
      } else {
        var errorArray = response.error;
        for (let i in errorArray) {
          console.log(
            'Received error: tagId= ' +
              errorArray[i].tagId +
              ', errorCode= ' +
              errorArray[i].errorCode +
              ', errorDescription= ' +
              errorArray[i].errorDescription
          );
        }
      }
    });
  };

  return (
    <div>
      <div className='fs-4 mb-4 fw-semibold '>Add new payment method:</div>
      <div className='text-gray-700 mb-7'>
        Your card information will not be stored during the checkout process for your security and
        privacy.
      </div>

      <div>
        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Name On Card</label>
          <input
            type='text'
            className='form-control form-control-solid text-uppercase '
            placeholder='Type card holder name'
            onChange={(e) => {
              setCardHolder(e.target.value);
            }}
            onBlur={(e) => {
              setPFErrors({...pfErrors, name: !e.target.value ? 'Enter card holder' : ''});
            }}
          />
          <div className='text-danger'>{pfErrors.name}</div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
            <label className='required fs-6 fw-semibold form-label mb-2'>Card Number</label>
            <div className='position-relative'>
              <div className='p-inputgroup flex-1'>
                <div className='w-100 bg-light border-0 h-50px' data-bluesnap='ccn'></div>

                <span className='p-inputgroup-addon border-0 bg-light'>
                  {/* {formik.values.cardType === 'visa' && ( */}
                  <img
                    src={toAbsoluteUrl(
                      `/media/svg/card-logos/${cardType?.toLocaleLowerCase()}.svg`
                    )}
                    alt=''
                    className='h-25px  '
                  />
                  {/* )} */}

                  {/* {formik.values.cardType === 'mastercard' && (
                      <img
                        src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                        alt=''
                        className='h-25px'
                      />
                    )} */}
                </span>
              </div>
            </div>
            <div className='text-danger'>{pfErrors.ccn}</div>
          </div>
        </div>
        <div className='col-md-4'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Expiration Date</label>
          <div className='h-50px' data-bluesnap='exp'></div>
          <div className='text-danger'>{pfErrors.exp}</div>
        </div>
        <div className='col-md-4'>
          <label className='required fs-6 fw-semibold form-label mb-2'>CVV</label>
          <div className='h-50px' data-bluesnap='cvv'></div>
          <div className='text-danger'>{pfErrors.cvv}</div>
        </div>
      </div>

      <button type='button' onClick={submitCard} className='btn btn-primary'>
        Submit Card
      </button>
    </div>
  );
}
