import clsx from 'clsx';
import {useState, useEffect} from 'react';
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components';
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers';
import useRealUserRole from '../../../../app/core/useCheckRole';
import {usePermissionChecker} from '../../../../admin/pages/roles/hooks/permission-checker';
import {useVideosQuery} from '../VideosQueryProvider';
import useVideosPageStore from '../videos-page.store';

export const videosSortingOptions = [
  {fieldName: 'experience_name', label: 'Name'},
  {fieldName: 'created_date', label: 'Created date'},
  {fieldName: 'views_count', label: 'Views Count'},
  {fieldName: 'status', label: 'Status'},
];

export function VideoSorting() {
  const {filters, updateFilters} = useVideosPageStore();
  const [isLoading] = useState(false);
  const [sortBy, setSortBy] = useState<string>();
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>();
  const [options, setOptions] = useState(videosSortingOptions);
  const {hasPermission} = usePermissionChecker();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    setSortBy('');
    setOrderBy(undefined);
    updateFilters({sort: undefined, order: undefined, ...initialQueryState});
  };

  const filterData = () => {
    updateFilters({
      sort: sortBy,
      order: orderBy,
      ...initialQueryState,
    });
  };

  return (
    <>
      {/* Toggle */}
      <button
        type='button'
        className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG
          path='/media/icons/duotune/text/txt004.svg'
          className={clsx('svg-icon-2', {'text-primary': filters?.sort})}
        />
        {/* Sort */}
      </button>

      {/* Menu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <header className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Sorting Options</div>
        </header>

        <section className='separator border-gray-200'></section>

        <form className='px-7 py-5' data-kt-user-table-filter='form'>
          <fieldset className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Sort By:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setSortBy(e.target.value)}
              value={sortBy}
            >
              <option value=''></option>
              {options.map((option) => (
                <option key={option.fieldName} value={option.fieldName}>
                  {option.label}
                </option>
              ))}
            </select>
          </fieldset>

          <fieldset className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Direction:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              data-control='select2'
              onChange={(e) => setOrderBy(e.target.value as any)}
              value={orderBy}
            >
              <option value=''></option>
              <option value='asc'>Ascending</option>
              <option value='desc'>Descending</option>
            </select>
          </fieldset>

          <footer className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </footer>
        </form>
      </div>
    </>
  );
}
