import clsx from 'clsx';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {createResellerCustomer, createUserByAdmin} from '../../../api/users-api';
import {FormFieldErrorMessage} from '../../../../../../shared/components/FormFieldErrorMessage';
import {useAuth} from '../../../../auth';
import {ApiError} from '../../../../auth/core/apiModels';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import Authorize from '../../../../auth/Authorize';
import {UserRolesEnum} from '../../../../models-management/types';
import {getAllOrganizations} from '../../../api/organizations-api';
import {Organization, PermissionLevelEnum, Role} from '../../../../app/types';
import {Dropdown} from 'primereact/dropdown';
import {GetRolesByOrg, getRoles} from '../../roles/roles-api';
import {usePermissionChecker} from '../../roles/hooks/permission-checker';

const initialValues = {
  first_name: '',
  last_name: '',
  org: undefined,
  role: undefined,
  is_org_admin: undefined,
  is_super_admin: undefined,
  company: '',
  email: '',
  api_key: '',
  job_title: '',
};

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
      'The email you entered is not valid'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  company: Yup.string().min(3, 'Minimum 3 symbols').max(200, 'Maximum 300 symbols'),
  // .required('Organization is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  api_key: Yup.string(),
});

interface Props {
  org_id?: number;
  isAdmin?: boolean;
  submitComplete: () => void;
}

const NewUser = ({submitComplete, org_id, isAdmin}: Props) => {
  const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const {currentUser} = useAuth();
  const [orgRoles, setOrgRoles] = useState<Role[]>([]);
  const [hasSystemLevelPermission, setHasSystemLevelPermission] = useState(false);
  const {getPermission} = usePermissionChecker();

  const formik = useFormik({
    initialValues: {...initialValues, is_org_admin: isAdmin, org: org_id},
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // Validate
      if (
        !formik.values.is_org_admin &&
        !formik.values.is_super_admin &&
        formik.values.role === undefined
      ) {
        setStatus('Please select the Role');
        formik.setFieldError('role', 'Required!');
        return;
      }

      setLoading(true);

      try {
        if (currentUser?.is_super_admin) await createUserByAdmin({...values});
        else await createResellerCustomer(values);

        toast.success('User saved successfully');

        setSubmitted(true);
        submitComplete();
      } catch (error: any) {
        console.error(error);

        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData?.msg) setStatus(responseData?.msg);
        else setStatus('Failed to submit new user');
      }
    },
  });

  useEffect(() => {
    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs(orgs);
        if (org_id) formik.setFieldValue('org', org_id);
      };

      getOrgs();

      const level = getPermission('users.create-user')?.level;
      setHasSystemLevelPermission(level === PermissionLevelEnum.SYSTEM);

      if (level !== PermissionLevelEnum.SYSTEM) formik.setFieldValue('org', currentUser?.org_id);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (org_id === undefined && formik.values.org === undefined) return setOrgRoles([]);

    if (org_id !== undefined && formik.values.org === undefined)
      formik.setFieldValue('org', org_id);

    const getOrgRoles = async () => {
      let roles: Role[] = [];

      if (currentUser?.is_super_admin) roles = await GetRolesByOrg(formik.values.org ?? 0);
      else if (currentUser?.is_org_admin) roles = await getRoles();
      else return;

      setOrgRoles(roles);
    };

    getOrgRoles();
  }, [org_id, formik.values.org]);

  useEffect(() => {
    if (formik.values.is_org_admin || formik.values.is_super_admin)
      formik.setFieldValue('role', null);
  }, [formik.values.is_org_admin, formik.values.is_super_admin]);

  // RENDERING
  return (
    <>
      <ModelHeaderLayout
        title={isAdmin ? 'New Admin' : 'New user'}
        showCloseButton
        onClose={submitComplete}
      >
        {/* {hasSystemLevelPermission && <div className='badge badge-warning'>System User</div>} */}
      </ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': loading,
        })}
      >
        <div className='text-muted mb-5'>
          Please enter user details and submit the form, we will send an email confirmation to user
          with a default password, he have to change it once he logged in.
        </div>

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='user-form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* User Type */}
          {(currentUser?.is_super_admin || true) && (
            <fieldset className='fv-row mb-4'>
              <label className='form-label fw-bolder text-dark fs-6 me-3'>Type</label>

              <div>
                <div className='form-check form-check-custom form-check-solid d-inline-block me-8'>
                  <input
                    name='type'
                    className='form-check-input'
                    type='radio'
                    value='3d'
                    defaultChecked={formik.values.is_org_admin}
                    onClick={() => formik.setFieldValue('is_org_admin', true)}
                    id='admin'
                  />
                  <label className='form-check-label' htmlFor='admin'>
                    Admin
                  </label>
                </div>

                <div className='form-check form-check-custom form-check-solid d-inline-block'>
                  <input
                    name='type'
                    className='form-check-input'
                    type='radio'
                    value='3d'
                    defaultChecked={!formik.values.is_org_admin}
                    onClick={() => formik.setFieldValue('is_org_admin', false)}
                    id='user'
                  />
                  <label className='form-check-label' htmlFor='user'>
                    User
                  </label>
                </div>
              </div>
            </fieldset>
          )}

          {/* Organization */}
          <div className='row'>
            {/* show org for vree admins  */}
            {(!!currentUser?.is_super_admin || hasSystemLevelPermission) && (
              <fieldset className='fv-row mb-4 col-md-6'>
                <div className=''>
                  <label className='form-label fw-bolder text-dark fs-6'>Organization</label>

                  <Dropdown
                    className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                    value={formik.values.org}
                    onChange={(e: any) => formik.setFieldValue('org', e.value)}
                    options={orgs}
                    optionLabel='name'
                    optionValue='id'
                    placeholder='Choose organization'
                    showClear
                  />
                </div>
              </fieldset>
            )}

            {/* show role if org is chosen */}
            {formik.values.org !== undefined && !formik.values.is_org_admin && (
              <fieldset className='col-md-6 fv-row mb-4 '>
                <label className='form-label fw-bolder text-dark fs-6'>Role</label>

                <Dropdown
                  className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                  {...formik.getFieldProps('role')}
                  options={orgRoles}
                  optionLabel='name'
                  optionValue='id'
                  placeholder='Choose Role'
                  showClear
                />

                {formik.touched.role && formik.errors.role && (
                  <FormFieldErrorMessage>{formik.errors.role}</FormFieldErrorMessage>
                )}
              </fieldset>
            )}
          </div>

          {/* name */}
          <fieldset className='row fv-row mb-4'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>First name</label>
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('first_name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                  },
                  {
                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                  }
                )}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <FormFieldErrorMessage>{formik.errors.first_name}</FormFieldErrorMessage>
              )}
            </div>
            <div className='col-xl-6'>
              <div className='fv-row '>
                <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                <input
                  placeholder='Last name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('last_name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.last_name && formik.errors.last_name,
                    },
                    {
                      'is-valid': formik.touched.last_name && !formik.errors.last_name,
                    }
                  )}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <FormFieldErrorMessage>{formik.errors.last_name}</FormFieldErrorMessage>
                )}
              </div>
            </div>
          </fieldset>

          {/* Company */}
          <fieldset className='row fv-row mb-4'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Company</label>
              <input
                placeholder='Company name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('company')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mw-400px',
                  {'is-invalid': formik.touched.company && formik.errors.company},
                  {
                    'is-valid': formik.touched.company && !formik.errors.company,
                  }
                )}
              />
              {formik.touched.company && formik.errors.company && (
                <FormFieldErrorMessage>{formik.errors.company}</FormFieldErrorMessage>
              )}
            </div>

            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Job Title</label>
              <input
                placeholder='Job title'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('job_title')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mw-400px',
                  {'is-invalid': formik.touched.job_title && formik.errors.job_title},
                  {
                    'is-valid': formik.touched.job_title && !formik.errors.job_title,
                  }
                )}
              />
              {formik.touched.job_title && formik.errors.job_title && (
                <FormFieldErrorMessage>{formik.errors.job_title}</FormFieldErrorMessage>
              )}
            </div>
          </fieldset>

          {/* Api Key */}
          {(currentUser?.is_org_admin || currentUser?.is_super_admin) && (
            <fieldset className='row fv-row mb-4'>
              <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Api Key</label>
                <input
                  placeholder='Api Key'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('api_key')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid mw-400px',
                    {
                      'is-invalid': formik.touched.api_key && formik.errors.api_key,
                    },
                    {
                      'is-valid': formik.touched.api_key && !formik.errors.api_key,
                    }
                  )}
                />

                <div className='text-muted ms-3'>
                  * this field is optional and can be used as your internal user identifier.
                </div>

                {formik.touched.api_key && formik.errors.api_key && (
                  <FormFieldErrorMessage>{formik.errors.api_key}</FormFieldErrorMessage>
                )}
              </div>
            </fieldset>
          )}

          {/* Email */}
          <fieldset className='fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid  mw-400px',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <FormFieldErrorMessage>{formik.errors.email}</FormFieldErrorMessage>
            )}
          </fieldset>
        </form>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={submitComplete} className='btn btn-light me-2'>
          Close
        </button>
        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </>
  );
};

export default NewUser;
